<template>
  <div>
    <el-dialog v-model="detailVisible" title="登记产品详情" width="50%">
      <div>

        <div id="printTable">
          <el-table
            :data="tableData"
            border
            style="width: 100%; margin-top: 20px"
          >
           <el-table-column label="产品类型" width="150">
             <template v-slot="{ row }">
               {{ row.product_type ? '赠品' : '主品' }}
             </template>
           </el-table-column>
            <el-table-column
              prop="product_name"
              show-overflow-tooltip
              label="产品名称"
            />
          <!--  <el-table-column prop="img" label="产品图片" /> -->
            <el-table-column label="产品图片" width="180px">
              <template #default="{ row, $index }">
                <el-image
                  @click="previewUrl($index)"
                  style="width: 50px; height: 50px"
                  :preview-teleported="true"
                  :src="'http://zct.yslts.com/' + row.img"
                  :initial-index="imgIndex"
                  fit="cover"
                  :preview-src-list="srcList"
                />
              </template>
            </el-table-column>
            <el-table-column prop="num" label="数量" />
          </el-table>
        </div>

        <!-- 分页 -->
        <el-row style="margin-top: 20px">
          <el-col>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-sizes="[10, 20, 50, 100, 500]"
              :page-size="search.limit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="search.total"
            >
            </el-pagination>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import { useVModel } from '@vueuse/core'
import {
  recordproListAPI
} from '@/api/record'
// import { ElMessage } from 'element-plus'
const props = defineProps({
  modelValue: {
    // 接收 v-model
    type: Boolean,
    required: true
  },

  hxID: {
    type: String,
    required: true
  }
})
defineEmits(['update:modelValue']) // 注册 v-model 事件
const detailVisible = useVModel(props) // 得到一个相应式的数据

/** 动态显示 S */
const type = ref(['项目名称', '执收编码', '数量', '收费金额', '发放状态'])
watch(
  () => type,
  (val) => {
    console.log(val.value)
  },
  {
    deep: true
  }
)

/** 动态显示 E */

/** 表格数据 S */
const search = ref({
  page: 1,
  limit: 10,
  total: 0,
  id: ''
})
watch(
  () => props.hxID,
  (val) => {
    search.value.id = val
    // addDetailForm.value.pay_settings_id = val
    onOrderDetails()
  }
)
const tableData = ref([])
const onOrderDetails = async () => {
  const { data, total } = await recordproListAPI(search.value)
  search.value.total = total
  tableData.value = data
  console.log(data)
}

// 修改 E

/** 表格数据 E */

/** 明细弹窗 E */

/**
 *  分页 S
 */
// 每页获取多少数据
const handleSizeChange = (val) => {
  search.value.limit = val
  onOrderDetails()
}

// 页码值
const handleCurrentChange = (val) => {
  search.value.page = val
  onOrderDetails()
}

/**
 *  分页 E
 */
</script>

<style></style>
