<template>
  <div>
   <el-form :model="search">
     <el-row :gutter="20">
       <el-col :span="5">
         <el-form-item label="员工姓名">
           <el-input v-model="search.staff_name" placeholder="请输入员工姓名" />
         </el-form-item>
       </el-col>
       <el-col :span="5">
         <el-form-item label="员工手机号">
           <el-input v-model="search.staff_phone" placeholder="请输入员工手机号" />
         </el-form-item>
       </el-col>
       <el-col :span="5">
         <el-form-item label="顾客昵称">
           <el-input v-model="search.custom_nickname" placeholder="请输入顾客昵称" />
         </el-form-item>
       </el-col>
       <el-col :span="5">
         <el-form-item label="顾客手机号">
           <el-input v-model="search.custom_phone" placeholder="请输入顾客手机号" />
         </el-form-item>
       </el-col>
       <el-col :span="2">
         <el-form-item>
           <el-button type="primary" @click="onSearchBtn" :icon="Search"
             >搜索</el-button
           >
         </el-form-item>
       </el-col>
       <el-col :span="1">
         <el-button
           :loading="loading"
           type="success"
           @click="onPayTheFeesexport"
         >
<!--         <el-button-->
<!--           type="success"-->
<!--           @click="onPayTheFeesexport"-->
<!--         >-->
           导出
         </el-button>
       </el-col>
     </el-row>
   </el-form>

    <!-- 表格 -->

    <el-table
      :data="listData"
      border
      style="width: 100%"
      :header-cell-style="{ background: '#f2f2f2' }"
    >
     <el-table-column prop="id" label="id" width="70" />
       <el-table-column prop="address" label="服务省市" width="100px" />
       <el-table-column prop="staff_name" label="员工姓名" width="80px" />
       <el-table-column prop="staff_phone" label="员工手机号" width="150px" />
       <el-table-column prop="custom_nickname" label="顾客昵称" width="90px" />
       <el-table-column prop="custom_phone" label="顾客手机号" width="150px" />
       <el-table-column prop="store_name" label="门店名称" width="200px" />
       <el-table-column prop="region" label="门店所在区域" width="200px" />
       <el-table-column prop="address" label="门店详细地址" width="200px" />
       <el-table-column prop="hx_time" label="登记时间" width="150px" />
       <el-table-column prop="add_time" label="添加时间" width="150px" />
       <el-table-column prop="demo" label="备注" width="100px" />
       <el-table-column label="操作" align="center" width="180" fixed="right">
         <template #default="{ row }">
           <el-button type="primary" size="small" @click="onEditBtn(row)"
             >产品详情</el-button
           >
         </template>
       </el-table-column>
      <!-- <el-table-column label="操作" align="center">
         <template #default="{ row }">
           <el-button type="primary" size="small" @click="onEditBtn(row)"
             >查看详情</el-button
           >
         </template>
       </el-table-column> -->
     </el-table>

    <!-- 分页 -->

    <el-row style="margin-top: 20px">
      <el-col>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="search.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="search.total"
        >
        </el-pagination>
      </el-col>
    </el-row>
   <productDetail
      v-model="oldStudentShow"
      :hxID="hxID"
      :onListData="onListData"
    ></productDetail>
  </div>
</template>

<script setup>
// articleListAPI
import { ref } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { recordListAPI, reportRecordAPI } from '@/api/record'
import productDetail from './productDetail.vue'

/**
 *  表单 S
 */
// 表单数据源
const search = ref({
  class_id: '',
  title: '',
  page: 1,
  limit: 10,
  total: 0
})
// 搜索
const onSearchBtn = () => {
  onListData()
}

/**
 *  表单 E
 */

/*
 *  表格： S
 */

// 列表
const listData = ref([])
const onListData = async () => {
  const { data, total } = await recordListAPI(search.value)
  search.value.total = total
  listData.value = data
}
onListData()

/*
 *  表格： E
 */

/**
 *  分页 S
 */

// 每页获取多少数据
const handleSizeChange = (val) => {
  search.value.limit = val
  onListData()
}

// 修改
const oldStudentShow = ref(false)
const hxID = ref({})
const onEditBtn = ({ id }) => {
  hxID.value = id
  oldStudentShow.value = true
}

/** 导出 E */
const loading = ref(false)
const onPayTheFeesexport = async () => {
  loading.value = true
  const data = await reportRecordAPI(search.value)
  window.location.href = `https://zct.yslts.com${data}`
  loading.value = false
}

// 页码值
const handleCurrentChange = (val) => {
  search.value.page = val
  onListData()
}

/**
 *  分页 E
 */
</script>

<style lang="scss" scoped></style>
