import request from '@/utils/request'

// 促销员登记列表
export const recordListAPI = (params) => {
  return request({
    url: '/recordList',
    method: 'GET',
    params
  })
}

// 登记产品列表
export const recordproListAPI = (params) => {
  return request({
    url: '/recordproList',
    method: 'GET',
    params
  })
}

// 导出
export const reportRecordAPI = (params) => {
  return request({
    url: '/reportRecord',
    method: 'GET',
    params
  })
}
